import _toConsumableArray from 'babel-runtime/helpers/toConsumableArray';
import PropTypes from '../_util/vue-types';
import { cloneElement } from '../_util/vnode';
import { filterEmpty, getComponentFromProp, getSlotOptions } from '../_util/props-util';
import warning from '../_util/warning';
import { ConfigConsumerProps } from '../config-provider';
import BreadcrumbItem from './BreadcrumbItem';
import Menu from '../menu';

var Route = PropTypes.shape({
  path: PropTypes.string,
  breadcrumbName: PropTypes.string,
  children: PropTypes.array
}).loose;

var BreadcrumbProps = {
  prefixCls: PropTypes.string,
  routes: PropTypes.arrayOf(Route),
  params: PropTypes.any,
  separator: PropTypes.any,
  itemRender: PropTypes.func
};

function getBreadcrumbName(route, params) {
  if (!route.breadcrumbName) {
    return null;
  }
  var paramsKeys = Object.keys(params).join('|');
  var name = route.breadcrumbName.replace(new RegExp(':(' + paramsKeys + ')', 'g'), function (replacement, key) {
    return params[key] || replacement;
  });
  return name;
}

export default {
  name: 'ABreadcrumb',
  props: BreadcrumbProps,
  inject: {
    configProvider: { 'default': function _default() {
        return ConfigConsumerProps;
      } }
  },
  methods: {
    defaultItemRender: function defaultItemRender(_ref) {
      var route = _ref.route,
          params = _ref.params,
          routes = _ref.routes,
          paths = _ref.paths;
      var h = this.$createElement;

      var isLastItem = routes.indexOf(route) === routes.length - 1;
      var name = getBreadcrumbName(route, params);
      return isLastItem ? h('span', [name]) : h(
        'a',
        {
          attrs: { href: '#/' + paths.join('/') }
        },
        [name]
      );
    },
    getPath: function getPath(path, params) {
      path = (path || '').replace(/^\//, '');
      Object.keys(params).forEach(function (key) {
        path = path.replace(':' + key, params[key]);
      });
      return path;
    },
    addChildPath: function addChildPath(paths, childPath, params) {
      var originalPaths = [].concat(_toConsumableArray(paths));
      var path = this.getPath(childPath, params);
      if (path) {
        originalPaths.push(path);
      }
      return originalPaths;
    },
    genForRoutes: function genForRoutes(_ref2) {
      var _this = this;

      var _ref2$routes = _ref2.routes,
          routes = _ref2$routes === undefined ? [] : _ref2$routes,
          _ref2$params = _ref2.params,
          params = _ref2$params === undefined ? {} : _ref2$params,
          separator = _ref2.separator,
          _ref2$itemRender = _ref2.itemRender,
          itemRender = _ref2$itemRender === undefined ? this.defaultItemRender : _ref2$itemRender;
      var h = this.$createElement;

      var paths = [];
      return routes.map(function (route) {
        var path = _this.getPath(route.path, params);

        if (path) {
          paths.push(path);
        }
        // generated overlay by route.children
        var overlay = null;
        if (route.children && route.children.length) {
          overlay = h(Menu, [route.children.map(function (child) {
            return h(
              Menu.Item,
              { key: child.path || child.breadcrumbName },
              [itemRender({
                route: child,
                params: params,
                routes: routes,
                paths: _this.addChildPath(paths, child.path, params),
                h: _this.$createElement
              })]
            );
          })]);
        }

        return h(
          BreadcrumbItem,
          {
            attrs: {
              overlay: overlay,
              separator: separator
            },
            key: path || route.breadcrumbName
          },
          [itemRender({ route: route, params: params, routes: routes, paths: paths, h: _this.$createElement })]
        );
      });
    }
  },
  render: function render() {
    var h = arguments[0];

    var crumbs = void 0;
    var customizePrefixCls = this.prefixCls,
        routes = this.routes,
        _params = this.params,
        params = _params === undefined ? {} : _params,
        $slots = this.$slots,
        $scopedSlots = this.$scopedSlots;

    var getPrefixCls = this.configProvider.getPrefixCls;
    var prefixCls = getPrefixCls('breadcrumb', customizePrefixCls);

    var children = filterEmpty($slots['default']);
    var separator = getComponentFromProp(this, 'separator');
    var itemRender = this.itemRender || $scopedSlots.itemRender || this.defaultItemRender;
    if (routes && routes.length > 0) {
      // generated by route
      crumbs = this.genForRoutes({
        routes: routes,
        params: params,
        separator: separator,
        itemRender: itemRender
      });
    } else if (children.length) {
      crumbs = children.map(function (element, index) {
        warning(getSlotOptions(element).__ANT_BREADCRUMB_ITEM || getSlotOptions(element).__ANT_BREADCRUMB_SEPARATOR, 'Breadcrumb', "Only accepts Breadcrumb.Item and Breadcrumb.Separator as it's children");
        return cloneElement(element, {
          props: { separator: separator },
          key: index
        });
      });
    }
    return h(
      'div',
      { 'class': prefixCls },
      [crumbs]
    );
  }
};