var NoFound = {
  functional: true,
  render: function render() {
    var h = arguments[0];

    return h(
      "svg",
      {
        attrs: { width: "252", height: "294" }
      },
      [h("defs", [h("path", {
        attrs: { d: "M0 .387h251.772v251.772H0z" }
      })]), h(
        "g",
        {
          attrs: { fill: "none", fillRule: "evenodd" }
        },
        [h(
          "g",
          {
            attrs: { transform: "translate(0 .012)" }
          },
          [h("mask", {
            attrs: { fill: "#fff" }
          }), h("path", {
            attrs: {
              d: "M0 127.32v-2.095C0 56.279 55.892.387 124.838.387h2.096c68.946 0 124.838 55.892 124.838 124.838v2.096c0 68.946-55.892 124.838-124.838 124.838h-2.096C55.892 252.16 0 196.267 0 127.321",
              fill: "#E4EBF7",
              mask: "url(#b)"
            }
          })]
        ), h("path", {
          attrs: {
            d: "M39.755 130.84a8.276 8.276 0 1 1-16.468-1.66 8.276 8.276 0 0 1 16.468 1.66",
            fill: "#FFF"
          }
        }), h("path", {
          attrs: {
            d: "M36.975 134.297l10.482 5.943M48.373 146.508l-12.648 10.788",
            stroke: "#FFF",
            strokeWidth: "2"
          }
        }), h("path", {
          attrs: {
            d: "M39.875 159.352a5.667 5.667 0 1 1-11.277-1.136 5.667 5.667 0 0 1 11.277 1.136M57.588 143.247a5.708 5.708 0 1 1-11.358-1.145 5.708 5.708 0 0 1 11.358 1.145M99.018 26.875l29.82-.014a4.587 4.587 0 1 0-.003-9.175l-29.82.013a4.587 4.587 0 1 0 .003 9.176M110.424 45.211l29.82-.013a4.588 4.588 0 0 0-.004-9.175l-29.82.013a4.587 4.587 0 1 0 .004 9.175",
            fill: "#FFF"
          }
        }), h("path", {
          attrs: {
            d: "M112.798 26.861v-.002l15.784-.006a4.588 4.588 0 1 0 .003 9.175l-15.783.007v-.002a4.586 4.586 0 0 0-.004-9.172M184.523 135.668c-.553 5.485-5.447 9.483-10.931 8.93-5.485-.553-9.483-5.448-8.93-10.932.552-5.485 5.447-9.483 10.932-8.93 5.485.553 9.483 5.447 8.93 10.932",
            fill: "#FFF"
          }
        }), h("path", {
          attrs: {
            d: "M179.26 141.75l12.64 7.167M193.006 156.477l-15.255 13.011",
            stroke: "#FFF",
            strokeWidth: "2"
          }
        }), h("path", {
          attrs: {
            d: "M184.668 170.057a6.835 6.835 0 1 1-13.6-1.372 6.835 6.835 0 0 1 13.6 1.372M203.34 153.325a6.885 6.885 0 1 1-13.7-1.382 6.885 6.885 0 0 1 13.7 1.382",
            fill: "#FFF"
          }
        }), h("path", {
          attrs: {
            d: "M151.931 192.324a2.222 2.222 0 1 1-4.444 0 2.222 2.222 0 0 1 4.444 0zM225.27 116.056a2.222 2.222 0 1 1-4.445 0 2.222 2.222 0 0 1 4.444 0zM216.38 151.08a2.223 2.223 0 1 1-4.446-.001 2.223 2.223 0 0 1 4.446 0zM176.917 107.636a2.223 2.223 0 1 1-4.445 0 2.223 2.223 0 0 1 4.445 0zM195.291 92.165a2.223 2.223 0 1 1-4.445 0 2.223 2.223 0 0 1 4.445 0zM202.058 180.711a2.223 2.223 0 1 1-4.446 0 2.223 2.223 0 0 1 4.446 0z",
            stroke: "#FFF",
            strokeWidth: "2"
          }
        }), h("path", {
          attrs: {
            stroke: "#FFF",
            strokeWidth: "2",
            d: "M214.404 153.302l-1.912 20.184-10.928 5.99M173.661 174.792l-6.356 9.814h-11.36l-4.508 6.484M174.941 125.168v-15.804M220.824 117.25l-12.84 7.901-15.31-7.902V94.39"
          }
        }), h("path", {
          attrs: {
            d: "M166.588 65.936h-3.951a4.756 4.756 0 0 1-4.743-4.742 4.756 4.756 0 0 1 4.743-4.743h3.951a4.756 4.756 0 0 1 4.743 4.743 4.756 4.756 0 0 1-4.743 4.742",
            fill: "#FFF"
          }
        }), h("path", {
          attrs: {
            d: "M174.823 30.03c0-16.281 13.198-29.48 29.48-29.48 16.28 0 29.48 13.199 29.48 29.48 0 16.28-13.2 29.48-29.48 29.48-16.282 0-29.48-13.2-29.48-29.48",
            fill: "#1890FF"
          }
        }), h("path", {
          attrs: {
            d: "M205.952 38.387c.5.5.785 1.142.785 1.928s-.286 1.465-.785 1.964c-.572.5-1.214.75-2 .75-.785 0-1.429-.285-1.929-.785-.572-.5-.82-1.143-.82-1.929s.248-1.428.82-1.928c.5-.5 1.144-.75 1.93-.75.785 0 1.462.25 1.999.75m4.285-19.463c1.428 1.249 2.143 2.963 2.143 5.142 0 1.712-.427 3.13-1.219 4.25-.067.096-.137.18-.218.265-.416.429-1.41 1.346-2.956 2.699a5.07 5.07 0 0 0-1.428 1.75 5.207 5.207 0 0 0-.536 2.357v.5h-4.107v-.5c0-1.357.215-2.536.714-3.5.464-.964 1.857-2.464 4.178-4.536l.43-.5c.643-.785.964-1.643.964-2.535 0-1.18-.358-2.108-1-2.785-.678-.68-1.643-1.001-2.858-1.001-1.536 0-2.642.464-3.357 1.43-.37.5-.621 1.135-.76 1.904a1.999 1.999 0 0 1-1.971 1.63h-.004c-1.277 0-2.257-1.183-1.98-2.43.337-1.518 1.02-2.78 2.073-3.784 1.536-1.5 3.607-2.25 6.25-2.25 2.32 0 4.214.607 5.642 1.894",
            fill: "#FFF"
          }
        }), h("path", {
          attrs: {
            d: "M52.04 76.131s21.81 5.36 27.307 15.945c5.575 10.74-6.352 9.26-15.73 4.935-10.86-5.008-24.7-11.822-11.577-20.88",
            fill: "#FFB594"
          }
        }), h("path", {
          attrs: {
            d: "M90.483 67.504l-.449 2.893c-.753.49-4.748-2.663-4.748-2.663l-1.645.748-1.346-5.684s6.815-4.589 8.917-5.018c2.452-.501 9.884.94 10.7 2.278 0 0 1.32.486-2.227.69-3.548.203-5.043.447-6.79 3.132-1.747 2.686-2.412 3.624-2.412 3.624",
            fill: "#FFC6A0"
          }
        }), h("path", {
          attrs: {
            d: "M128.055 111.367c-2.627-7.724-6.15-13.18-8.917-15.478-3.5-2.906-9.34-2.225-11.366-4.187-1.27-1.231-3.215-1.197-3.215-1.197s-14.98-3.158-16.828-3.479c-2.37-.41-2.124-.714-6.054-1.405-1.57-1.907-2.917-1.122-2.917-1.122l-7.11-1.383c-.853-1.472-2.423-1.023-2.423-1.023l-2.468-.897c-1.645 9.976-7.74 13.796-7.74 13.796 1.795 1.122 15.703 8.3 15.703 8.3l5.107 37.11s-3.321 5.694 1.346 9.109c0 0 19.883-3.743 34.921-.329 0 0 3.047-2.546.972-8.806.523-3.01 1.394-8.263 1.736-11.622.385.772 2.019 1.918 3.14 3.477 0 0 9.407-7.365 11.052-14.012-.832-.723-1.598-1.585-2.267-2.453-.567-.736-.358-2.056-.765-2.717-.669-1.084-1.804-1.378-1.907-1.682",
            fill: "#FFF"
          }
        }), h("path", {
          attrs: {
            d: "M101.09 289.998s4.295 2.041 7.354 1.021c2.821-.94 4.53.668 7.08 1.178 2.55.51 6.874 1.1 11.686-1.26-.103-5.51-6.889-3.98-11.96-6.713-2.563-1.38-3.784-4.722-3.598-8.799h-9.402s-1.392 10.52-1.16 14.573",
            fill: "#CBD1D1"
          }
        }), h("path", {
          attrs: {
            d: "M101.067 289.826s2.428 1.271 6.759.653c3.058-.437 3.712.481 7.423 1.031 3.712.55 10.724-.069 11.823-.894.413 1.1-.343 2.063-.343 2.063s-1.512.603-4.812.824c-2.03.136-5.8.291-7.607-.503-1.787-1.375-5.247-1.903-5.728-.241-3.918.95-7.355-.286-7.355-.286l-.16-2.647z",
            fill: "#2B0849"
          }
        }), h("path", {
          attrs: {
            d: "M108.341 276.044h3.094s-.103 6.702 4.536 8.558c-4.64.618-8.558-2.303-7.63-8.558",
            fill: "#A4AABA"
          }
        }), h("path", {
          attrs: {
            d: "M57.542 272.401s-2.107 7.416-4.485 12.306c-1.798 3.695-4.225 7.492 5.465 7.492 6.648 0 8.953-.48 7.423-6.599-1.53-6.12.266-13.199.266-13.199h-8.669z",
            fill: "#CBD1D1"
          }
        }), h("path", {
          attrs: {
            d: "M51.476 289.793s2.097 1.169 6.633 1.169c6.083 0 8.249-1.65 8.249-1.65s.602 1.114-.619 2.165c-.993.855-3.597 1.591-7.39 1.546-4.145-.048-5.832-.566-6.736-1.168-.825-.55-.687-1.58-.137-2.062",
            fill: "#2B0849"
          }
        }), h("path", {
          attrs: {
            d: "M58.419 274.304s.033 1.519-.314 2.93c-.349 1.42-1.078 3.104-1.13 4.139-.058 1.151 4.537 1.58 5.155.034.62-1.547 1.294-6.427 1.913-7.252.619-.825-4.903-2.119-5.624.15",
            fill: "#A4AABA"
          }
        }), h("path", {
          attrs: {
            d: "M99.66 278.514l13.378.092s1.298-54.52 1.853-64.403c.554-9.882 3.776-43.364 1.002-63.128l-12.547-.644-22.849.78s-.434 3.966-1.195 9.976c-.063.496-.682.843-.749 1.365-.075.585.423 1.354.32 1.966-2.364 14.08-6.377 33.104-8.744 46.677-.116.666-1.234 1.009-1.458 2.691-.04.302.211 1.525.112 1.795-6.873 18.744-10.949 47.842-14.277 61.885l14.607-.014s2.197-8.57 4.03-16.97c2.811-12.886 23.111-85.01 23.111-85.01l3.016-.521 1.043 46.35s-.224 1.234.337 2.02c.56.785-.56 1.123-.392 2.244l.392 1.794s-.449 7.178-.898 11.89c-.448 4.71-.092 39.165-.092 39.165",
            fill: "#7BB2F9"
          }
        }), h("path", {
          attrs: {
            d: "M76.085 221.626c1.153.094 4.038-2.019 6.955-4.935M106.36 225.142s2.774-1.11 6.103-3.883",
            stroke: "#648BD8",
            strokeWidth: "1.051",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M107.275 222.1s2.773-1.11 6.102-3.884",
            stroke: "#648BD8",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M74.74 224.767s2.622-.591 6.505-3.365M86.03 151.634c-.27 3.106.3 8.525-4.336 9.123M103.625 149.88s.11 14.012-1.293 15.065c-2.219 1.664-2.99 1.944-2.99 1.944M99.79 150.438s.035 12.88-1.196 24.377M93.673 175.911s7.212-1.664 9.431-1.664M74.31 205.861a212.013 212.013 0 0 1-.979 4.56s-1.458 1.832-1.009 3.776c.449 1.944-.947 2.045-4.985 15.355-1.696 5.59-4.49 18.591-6.348 27.597l-.231 1.12M75.689 197.807a320.934 320.934 0 0 1-.882 4.754M82.591 152.233L81.395 162.7s-1.097.15-.5 2.244c.113 1.346-2.674 15.775-5.18 30.43M56.12 274.418h13.31",
            stroke: "#648BD8",
            strokeWidth: "1.051",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M116.241 148.22s-17.047-3.104-35.893.2c.158 2.514-.003 4.15-.003 4.15s14.687-2.818 35.67-.312c.252-2.355.226-4.038.226-4.038",
            fill: "#192064"
          }
        }), h("path", {
          attrs: {
            d: "M106.322 151.165l.003-4.911a.81.81 0 0 0-.778-.815c-2.44-.091-5.066-.108-7.836-.014a.818.818 0 0 0-.789.815l-.003 4.906a.81.81 0 0 0 .831.813c2.385-.06 4.973-.064 7.73.017a.815.815 0 0 0 .842-.81",
            fill: "#FFF"
          }
        }), h("path", {
          attrs: {
            d: "M105.207 150.233l.002-3.076a.642.642 0 0 0-.619-.646 94.321 94.321 0 0 0-5.866-.01.65.65 0 0 0-.63.647v3.072a.64.64 0 0 0 .654.644 121.12 121.12 0 0 1 5.794.011c.362.01.665-.28.665-.642",
            fill: "#192064"
          }
        }), h("path", {
          attrs: {
            d: "M100.263 275.415h12.338M101.436 270.53c.006 3.387.042 5.79.111 6.506M101.451 264.548a915.75 915.75 0 0 0-.015 4.337M100.986 174.965l.898 44.642s.673 1.57-.225 2.692c-.897 1.122 2.468.673.898 2.243-1.57 1.57.897 1.122 0 3.365-.596 1.489-.994 21.1-1.096 35.146",
            stroke: "#648BD8",
            strokeWidth: "1.051",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M46.876 83.427s-.516 6.045 7.223 5.552c11.2-.712 9.218-9.345 31.54-21.655-.786-2.708-2.447-4.744-2.447-4.744s-11.068 3.11-22.584 8.046c-6.766 2.9-13.395 6.352-13.732 12.801M104.46 91.057l.941-5.372-8.884-11.43-5.037 5.372-1.74 7.834a.321.321 0 0 0 .108.32c.965.8 6.5 5.013 14.347 3.544a.332.332 0 0 0 .264-.268",
            fill: "#FFC6A0"
          }
        }), h("path", {
          attrs: {
            d: "M93.942 79.387s-4.533-2.853-2.432-6.855c1.623-3.09 4.513 1.133 4.513 1.133s.52-3.642 3.121-3.642c.52-1.04 1.561-4.162 1.561-4.162s11.445 2.601 13.526 3.121c0 5.203-2.304 19.424-7.84 19.861-8.892.703-12.449-9.456-12.449-9.456",
            fill: "#FFC6A0"
          }
        }), h("path", {
          attrs: {
            d: "M113.874 73.446c2.601-2.081 3.47-9.722 3.47-9.722s-2.479-.49-6.64-2.05c-4.683-2.081-12.798-4.747-17.48.976-9.668 3.223-2.05 19.823-2.05 19.823l2.713-3.021s-3.935-3.287-2.08-6.243c2.17-3.462 3.92 1.073 3.92 1.073s.637-2.387 3.581-3.342c.355-.71 1.036-2.674 1.432-3.85a1.073 1.073 0 0 1 1.263-.704c2.4.558 8.677 2.019 11.356 2.662.522.125.871.615.82 1.15l-.305 3.248z",
            fill: "#520038"
          }
        }), h("path", {
          attrs: {
            d: "M104.977 76.064c-.103.61-.582 1.038-1.07.956-.489-.083-.801-.644-.698-1.254.103-.61.582-1.038 1.07-.956.488.082.8.644.698 1.254M112.132 77.694c-.103.61-.582 1.038-1.07.956-.488-.083-.8-.644-.698-1.254.103-.61.582-1.038 1.07-.956.488.082.8.643.698 1.254",
            fill: "#552950"
          }
        }), h("path", {
          attrs: {
            stroke: "#DB836E",
            strokeWidth: "1.118",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M110.13 74.84l-.896 1.61-.298 4.357h-2.228"
          }
        }), h("path", {
          attrs: {
            d: "M110.846 74.481s1.79-.716 2.506.537",
            stroke: "#5C2552",
            strokeWidth: "1.118",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M92.386 74.282s.477-1.114 1.113-.716c.637.398 1.274 1.433.558 1.99-.717.556.159 1.67.159 1.67",
            stroke: "#DB836E",
            strokeWidth: "1.118",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M103.287 72.93s1.83 1.113 4.137.954",
            stroke: "#5C2552",
            strokeWidth: "1.118",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M103.685 81.762s2.227 1.193 4.376 1.193M104.64 84.308s.954.398 1.511.318M94.693 81.205s2.308 7.4 10.424 7.639",
            stroke: "#DB836E",
            strokeWidth: "1.118",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M81.45 89.384s.45 5.647-4.935 12.787M69 82.654s-.726 9.282-8.204 14.206",
            stroke: "#E4EBF7",
            strokeWidth: "1.101",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M129.405 122.865s-5.272 7.403-9.422 10.768",
            stroke: "#E4EBF7",
            strokeWidth: "1.051",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M119.306 107.329s.452 4.366-2.127 32.062",
            stroke: "#E4EBF7",
            strokeWidth: "1.101",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M150.028 151.232h-49.837a1.01 1.01 0 0 1-1.01-1.01v-31.688c0-.557.452-1.01 1.01-1.01h49.837c.558 0 1.01.453 1.01 1.01v31.688a1.01 1.01 0 0 1-1.01 1.01",
            fill: "#F2D7AD"
          }
        }), h("path", {
          attrs: {
            d: "M150.29 151.232h-19.863v-33.707h20.784v32.786a.92.92 0 0 1-.92.92",
            fill: "#F4D19D"
          }
        }), h("path", {
          attrs: {
            d: "M123.554 127.896H92.917a.518.518 0 0 1-.425-.816l6.38-9.113c.193-.277.51-.442.85-.442h31.092l-7.26 10.371z",
            fill: "#F2D7AD"
          }
        }), h("path", {
          attrs: { fill: "#CC9B6E", d: "M123.689 128.447H99.25v-.519h24.169l7.183-10.26.424.298z" }
        }), h("path", {
          attrs: {
            d: "M158.298 127.896h-18.669a2.073 2.073 0 0 1-1.659-.83l-7.156-9.541h19.965c.49 0 .95.23 1.244.622l6.69 8.92a.519.519 0 0 1-.415.83",
            fill: "#F4D19D"
          }
        }), h("path", {
          attrs: {
            fill: "#CC9B6E",
            d: "M157.847 128.479h-19.384l-7.857-10.475.415-.31 7.7 10.266h19.126zM130.554 150.685l-.032-8.177.519-.002.032 8.177z"
          }
        }), h("path", {
          attrs: {
            fill: "#CC9B6E",
            d: "M130.511 139.783l-.08-21.414.519-.002.08 21.414zM111.876 140.932l-.498-.143 1.479-5.167.498.143zM108.437 141.06l-2.679-2.935 2.665-3.434.41.318-2.397 3.089 2.384 2.612zM116.607 141.06l-.383-.35 2.383-2.612-2.397-3.089.41-.318 2.665 3.434z"
          }
        }), h("path", {
          attrs: {
            d: "M154.316 131.892l-3.114-1.96.038 3.514-1.043.092c-1.682.115-3.634.23-4.789.23-1.902 0-2.693 2.258 2.23 2.648l-2.645-.596s-2.168 1.317.504 2.3c0 0-1.58 1.217.561 2.58-.584 3.504 5.247 4.058 7.122 3.59 1.876-.47 4.233-2.359 4.487-5.16.28-3.085-.89-5.432-3.35-7.238",
            fill: "#FFC6A0"
          }
        }), h("path", {
          attrs: {
            d: "M153.686 133.577s-6.522.47-8.36.372c-1.836-.098-1.904 2.19 2.359 2.264 3.739.15 5.451-.044 5.451-.044",
            stroke: "#DB836E",
            strokeWidth: "1.051",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M145.16 135.877c-1.85 1.346.561 2.355.561 2.355s3.478.898 6.73.617",
            stroke: "#DB836E",
            strokeWidth: "1.051",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M151.89 141.71s-6.28.111-6.73-2.132c-.223-1.346.45-1.402.45-1.402M146.114 140.868s-1.103 3.16 5.44 3.533M151.202 129.932v3.477M52.838 89.286c3.533-.337 8.423-1.248 13.582-7.754",
            stroke: "#DB836E",
            strokeWidth: "1.051",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M168.567 248.318a6.647 6.647 0 0 1-6.647-6.647v-66.466a6.647 6.647 0 1 1 13.294 0v66.466a6.647 6.647 0 0 1-6.647 6.647",
            fill: "#5BA02E"
          }
        }), h("path", {
          attrs: {
            d: "M176.543 247.653a6.647 6.647 0 0 1-6.646-6.647v-33.232a6.647 6.647 0 1 1 13.293 0v33.232a6.647 6.647 0 0 1-6.647 6.647",
            fill: "#92C110"
          }
        }), h("path", {
          attrs: {
            d: "M186.443 293.613H158.92a3.187 3.187 0 0 1-3.187-3.187v-46.134a3.187 3.187 0 0 1 3.187-3.187h27.524a3.187 3.187 0 0 1 3.187 3.187v46.134a3.187 3.187 0 0 1-3.187 3.187",
            fill: "#F2D7AD"
          }
        }), h("path", {
          attrs: {
            d: "M88.979 89.48s7.776 5.384 16.6 2.842",
            stroke: "#E4EBF7",
            strokeWidth: "1.101",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        })]
      )]
    );
  }
};

export default NoFound;