import PropTypes from '../_util/vue-types';

export default {
  props: {
    prefixCls: PropTypes.string,
    overlay: PropTypes.any,
    trigger: PropTypes.any
  },
  updated: function updated() {
    var trigger = this.trigger;

    if (trigger) {
      trigger.forcePopupAlign();
    }
  },
  render: function render() {
    var h = arguments[0];
    var overlay = this.overlay,
        prefixCls = this.prefixCls;

    return h(
      'div',
      { 'class': prefixCls + '-inner', attrs: { role: 'tooltip' }
      },
      [typeof overlay === 'function' ? overlay() : overlay]
    );
  }
};