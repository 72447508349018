var Unauthorized = {
  functional: true,
  render: function render() {
    var h = arguments[0];

    return h(
      "svg",
      {
        attrs: { width: "251", height: "294" }
      },
      [h(
        "g",
        {
          attrs: { fill: "none", fillRule: "evenodd" }
        },
        [h("path", {
          attrs: {
            d: "M0 129.023v-2.084C0 58.364 55.591 2.774 124.165 2.774h2.085c68.574 0 124.165 55.59 124.165 124.165v2.084c0 68.575-55.59 124.166-124.165 124.166h-2.085C55.591 253.189 0 197.598 0 129.023",
            fill: "#E4EBF7"
          }
        }), h("path", {
          attrs: {
            d: "M41.417 132.92a8.231 8.231 0 1 1-16.38-1.65 8.231 8.231 0 0 1 16.38 1.65",
            fill: "#FFF"
          }
        }), h("path", {
          attrs: {
            d: "M38.652 136.36l10.425 5.91M49.989 148.505l-12.58 10.73",
            stroke: "#FFF",
            strokeWidth: "2"
          }
        }), h("path", {
          attrs: {
            d: "M41.536 161.28a5.636 5.636 0 1 1-11.216-1.13 5.636 5.636 0 0 1 11.216 1.13M59.154 145.261a5.677 5.677 0 1 1-11.297-1.138 5.677 5.677 0 0 1 11.297 1.138M100.36 29.516l29.66-.013a4.562 4.562 0 1 0-.004-9.126l-29.66.013a4.563 4.563 0 0 0 .005 9.126M111.705 47.754l29.659-.013a4.563 4.563 0 1 0-.004-9.126l-29.66.013a4.563 4.563 0 1 0 .005 9.126",
            fill: "#FFF"
          }
        }), h("path", {
          attrs: {
            d: "M114.066 29.503V29.5l15.698-.007a4.563 4.563 0 1 0 .004 9.126l-15.698.007v-.002a4.562 4.562 0 0 0-.004-9.122M185.405 137.723c-.55 5.455-5.418 9.432-10.873 8.882-5.456-.55-9.432-5.418-8.882-10.873.55-5.455 5.418-9.432 10.873-8.882 5.455.55 9.432 5.418 8.882 10.873",
            fill: "#FFF"
          }
        }), h("path", {
          attrs: {
            d: "M180.17 143.772l12.572 7.129M193.841 158.42L178.67 171.36",
            stroke: "#FFF",
            strokeWidth: "2"
          }
        }), h("path", {
          attrs: {
            d: "M185.55 171.926a6.798 6.798 0 1 1-13.528-1.363 6.798 6.798 0 0 1 13.527 1.363M204.12 155.285a6.848 6.848 0 1 1-13.627-1.375 6.848 6.848 0 0 1 13.626 1.375",
            fill: "#FFF"
          }
        }), h("path", {
          attrs: {
            d: "M152.988 194.074a2.21 2.21 0 1 1-4.42 0 2.21 2.21 0 0 1 4.42 0zM225.931 118.217a2.21 2.21 0 1 1-4.421 0 2.21 2.21 0 0 1 4.421 0zM217.09 153.051a2.21 2.21 0 1 1-4.421 0 2.21 2.21 0 0 1 4.42 0zM177.84 109.842a2.21 2.21 0 1 1-4.422 0 2.21 2.21 0 0 1 4.421 0zM196.114 94.454a2.21 2.21 0 1 1-4.421 0 2.21 2.21 0 0 1 4.421 0zM202.844 182.523a2.21 2.21 0 1 1-4.42 0 2.21 2.21 0 0 1 4.42 0z",
            stroke: "#FFF",
            strokeWidth: "2"
          }
        }), h("path", {
          attrs: {
            stroke: "#FFF",
            strokeWidth: "2",
            d: "M215.125 155.262l-1.902 20.075-10.87 5.958M174.601 176.636l-6.322 9.761H156.98l-4.484 6.449M175.874 127.28V111.56M221.51 119.404l-12.77 7.859-15.228-7.86V96.668"
          }
        }), h("path", {
          attrs: {
            d: "M180.68 29.32C180.68 13.128 193.806 0 210 0c16.193 0 29.32 13.127 29.32 29.32 0 16.194-13.127 29.322-29.32 29.322-16.193 0-29.32-13.128-29.32-29.321",
            fill: "#A26EF4"
          }
        }), h("path", {
          attrs: {
            d: "M221.45 41.706l-21.563-.125a1.744 1.744 0 0 1-1.734-1.754l.071-12.23a1.744 1.744 0 0 1 1.754-1.734l21.562.125c.964.006 1.74.791 1.735 1.755l-.071 12.229a1.744 1.744 0 0 1-1.754 1.734",
            fill: "#FFF"
          }
        }), h("path", {
          attrs: {
            d: "M215.106 29.192c-.015 2.577-2.049 4.654-4.543 4.64-2.494-.014-4.504-2.115-4.489-4.693l.04-6.925c.016-2.577 2.05-4.654 4.543-4.64 2.494.015 4.504 2.116 4.49 4.693l-.04 6.925zm-4.53-14.074a6.877 6.877 0 0 0-6.916 6.837l-.043 7.368a6.877 6.877 0 0 0 13.754.08l.042-7.368a6.878 6.878 0 0 0-6.837-6.917zM167.566 68.367h-3.93a4.73 4.73 0 0 1-4.717-4.717 4.73 4.73 0 0 1 4.717-4.717h3.93a4.73 4.73 0 0 1 4.717 4.717 4.73 4.73 0 0 1-4.717 4.717",
            fill: "#FFF"
          }
        }), h("path", {
          attrs: {
            d: "M168.214 248.838a6.611 6.611 0 0 1-6.61-6.611v-66.108a6.611 6.611 0 0 1 13.221 0v66.108a6.611 6.611 0 0 1-6.61 6.61",
            fill: "#5BA02E"
          }
        }), h("path", {
          attrs: {
            d: "M176.147 248.176a6.611 6.611 0 0 1-6.61-6.61v-33.054a6.611 6.611 0 1 1 13.221 0v33.053a6.611 6.611 0 0 1-6.61 6.611",
            fill: "#92C110"
          }
        }), h("path", {
          attrs: {
            d: "M185.994 293.89h-27.376a3.17 3.17 0 0 1-3.17-3.17v-45.887a3.17 3.17 0 0 1 3.17-3.17h27.376a3.17 3.17 0 0 1 3.17 3.17v45.886a3.17 3.17 0 0 1-3.17 3.17",
            fill: "#F2D7AD"
          }
        }), h("path", {
          attrs: {
            d: "M81.972 147.673s6.377-.927 17.566-1.28c11.729-.371 17.57 1.086 17.57 1.086s3.697-3.855.968-8.424c1.278-12.077 5.982-32.827.335-48.273-1.116-1.339-3.743-1.512-7.536-.62-1.337.315-7.147-.149-7.983-.1l-15.311-.347s-3.487-.17-8.035-.508c-1.512-.113-4.227-1.683-5.458-.338-.406.443-2.425 5.669-1.97 16.077l8.635 35.642s-3.141 3.61 1.219 7.085",
            fill: "#FFF"
          }
        }), h("path", {
          attrs: {
            d: "M75.768 73.325l-.9-6.397 11.982-6.52s7.302-.118 8.038 1.205c.737 1.324-5.616.993-5.616.993s-1.836 1.388-2.615 2.5c-1.654 2.363-.986 6.471-8.318 5.986-1.708.284-2.57 2.233-2.57 2.233",
            fill: "#FFC6A0"
          }
        }), h("path", {
          attrs: {
            d: "M52.44 77.672s14.217 9.406 24.973 14.444c1.061.497-2.094 16.183-11.892 11.811-7.436-3.318-20.162-8.44-21.482-14.496-.71-3.258 2.543-7.643 8.401-11.76M141.862 80.113s-6.693 2.999-13.844 6.876c-3.894 2.11-10.137 4.704-12.33 7.988-6.224 9.314 3.536 11.22 12.947 7.503 6.71-2.651 28.999-12.127 13.227-22.367",
            fill: "#FFB594"
          }
        }), h("path", {
          attrs: {
            d: "M76.166 66.36l3.06 3.881s-2.783 2.67-6.31 5.747c-7.103 6.195-12.803 14.296-15.995 16.44-3.966 2.662-9.754 3.314-12.177-.118-3.553-5.032.464-14.628 31.422-25.95",
            fill: "#FFC6A0"
          }
        }), h("path", {
          attrs: {
            d: "M64.674 85.116s-2.34 8.413-8.912 14.447c.652.548 18.586 10.51 22.144 10.056 5.238-.669 6.417-18.968 1.145-20.531-.702-.208-5.901-1.286-8.853-2.167-.87-.26-1.611-1.71-3.545-.936l-1.98-.869zM128.362 85.826s5.318 1.956 7.325 13.734c-.546.274-17.55 12.35-21.829 7.805-6.534-6.94-.766-17.393 4.275-18.61 4.646-1.121 5.03-1.37 10.23-2.929",
            fill: "#FFF"
          }
        }), h("path", {
          attrs: {
            d: "M78.18 94.656s.911 7.41-4.914 13.078",
            stroke: "#E4EBF7",
            strokeWidth: "1.051",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M87.397 94.68s3.124 2.572 10.263 2.572c7.14 0 9.074-3.437 9.074-3.437",
            stroke: "#E4EBF7",
            strokeWidth: ".932",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M117.184 68.639l-6.781-6.177s-5.355-4.314-9.223-.893c-3.867 3.422 4.463 2.083 5.653 4.165 1.19 2.082.848 1.143-2.083.446-5.603-1.331-2.082.893 2.975 5.355 2.091 1.845 6.992.955 6.992.955l2.467-3.851z",
            fill: "#FFC6A0"
          }
        }), h("path", {
          attrs: {
            d: "M105.282 91.315l-.297-10.937-15.918-.027-.53 10.45c-.026.403.17.788.515.999 2.049 1.251 9.387 5.093 15.799.424.287-.21.443-.554.431-.91",
            fill: "#FFB594"
          }
        }), h("path", {
          attrs: {
            d: "M107.573 74.24c.817-1.147.982-9.118 1.015-11.928a1.046 1.046 0 0 0-.965-1.055l-4.62-.365c-7.71-1.044-17.071.624-18.253 6.346-5.482 5.813-.421 13.244-.421 13.244s1.963 3.566 4.305 6.791c.756 1.041.398-3.731 3.04-5.929 5.524-4.594 15.899-7.103 15.899-7.103",
            fill: "#5C2552"
          }
        }), h("path", {
          attrs: {
            d: "M88.426 83.206s2.685 6.202 11.602 6.522c7.82.28 8.973-7.008 7.434-17.505l-.909-5.483c-6.118-2.897-15.478.54-15.478.54s-.576 2.044-.19 5.504c-2.276 2.066-1.824 5.618-1.824 5.618s-.905-1.922-1.98-2.321c-.86-.32-1.897.089-2.322 1.98-1.04 4.632 3.667 5.145 3.667 5.145",
            fill: "#FFC6A0"
          }
        }), h("path", {
          attrs: {
            stroke: "#DB836E",
            strokeWidth: "1.145",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            d: "M100.843 77.099l1.701-.928-1.015-4.324.674-1.406"
          }
        }), h("path", {
          attrs: {
            d: "M105.546 74.092c-.022.713-.452 1.279-.96 1.263-.51-.016-.904-.607-.882-1.32.021-.713.452-1.278.96-1.263.51.016.904.607.882 1.32M97.592 74.349c-.022.713-.452 1.278-.961 1.263-.509-.016-.904-.607-.882-1.32.022-.713.452-1.279.961-1.263.51.016.904.606.882 1.32",
            fill: "#552950"
          }
        }), h("path", {
          attrs: {
            d: "M91.132 86.786s5.269 4.957 12.679 2.327",
            stroke: "#DB836E",
            strokeWidth: "1.145",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M99.776 81.903s-3.592.232-1.44-2.79c1.59-1.496 4.897-.46 4.897-.46s1.156 3.906-3.457 3.25",
            fill: "#DB836E"
          }
        }), h("path", {
          attrs: {
            d: "M102.88 70.6s2.483.84 3.402.715M93.883 71.975s2.492-1.144 4.778-1.073",
            stroke: "#5C2552",
            strokeWidth: "1.526",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M86.32 77.374s.961.879 1.458 2.106c-.377.48-1.033 1.152-.236 1.809M99.337 83.719s1.911.151 2.509-.254",
            stroke: "#DB836E",
            strokeWidth: "1.145",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M87.782 115.821l15.73-3.012M100.165 115.821l10.04-2.008",
            stroke: "#E4EBF7",
            strokeWidth: "1.051",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M66.508 86.763s-1.598 8.83-6.697 14.078",
            stroke: "#E4EBF7",
            strokeWidth: "1.114",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M128.31 87.934s3.013 4.121 4.06 11.785",
            stroke: "#E4EBF7",
            strokeWidth: "1.051",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M64.09 84.816s-6.03 9.912-13.607 9.903",
            stroke: "#DB836E",
            strokeWidth: ".795",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M112.366 65.909l-.142 5.32s5.993 4.472 11.945 9.202c4.482 3.562 8.888 7.455 10.985 8.662 4.804 2.766 8.9 3.355 11.076 1.808 4.071-2.894 4.373-9.878-8.136-15.263-4.271-1.838-16.144-6.36-25.728-9.73",
            fill: "#FFC6A0"
          }
        }), h("path", {
          attrs: {
            d: "M130.532 85.488s4.588 5.757 11.619 6.214",
            stroke: "#DB836E",
            strokeWidth: ".75",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M121.708 105.73s-.393 8.564-1.34 13.612",
            stroke: "#E4EBF7",
            strokeWidth: "1.051",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M115.784 161.512s-3.57-1.488-2.678-7.14",
            stroke: "#648BD8",
            strokeWidth: "1.051",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M101.52 290.246s4.326 2.057 7.408 1.03c2.842-.948 4.564.673 7.132 1.186 2.57.514 6.925 1.108 11.772-1.269-.104-5.551-6.939-4.01-12.048-6.763-2.582-1.39-3.812-4.757-3.625-8.863h-9.471s-1.402 10.596-1.169 14.68",
            fill: "#CBD1D1"
          }
        }), h("path", {
          attrs: {
            d: "M101.496 290.073s2.447 1.281 6.809.658c3.081-.44 3.74.485 7.479 1.039 3.739.554 10.802-.07 11.91-.9.415 1.108-.347 2.077-.347 2.077s-1.523.608-4.847.831c-2.045.137-5.843.293-7.663-.507-1.8-1.385-5.286-1.917-5.77-.243-3.947.958-7.41-.288-7.41-.288l-.16-2.667z",
            fill: "#2B0849"
          }
        }), h("path", {
          attrs: {
            d: "M108.824 276.19h3.116s-.103 6.751 4.57 8.62c-4.673.624-8.62-2.32-7.686-8.62",
            fill: "#A4AABA"
          }
        }), h("path", {
          attrs: {
            d: "M57.65 272.52s-2.122 7.47-4.518 12.396c-1.811 3.724-4.255 7.548 5.505 7.548 6.698 0 9.02-.483 7.479-6.648-1.541-6.164.268-13.296.268-13.296H57.65z",
            fill: "#CBD1D1"
          }
        }), h("path", {
          attrs: {
            d: "M51.54 290.04s2.111 1.178 6.682 1.178c6.128 0 8.31-1.662 8.31-1.662s.605 1.122-.624 2.18c-1 .862-3.624 1.603-7.444 1.559-4.177-.049-5.876-.57-6.786-1.177-.831-.554-.692-1.593-.138-2.078",
            fill: "#2B0849"
          }
        }), h("path", {
          attrs: {
            d: "M58.533 274.438s.034 1.529-.315 2.95c-.352 1.431-1.087 3.127-1.139 4.17-.058 1.16 4.57 1.592 5.194.035.623-1.559 1.303-6.475 1.927-7.306.622-.831-4.94-2.135-5.667.15",
            fill: "#A4AABA"
          }
        }), h("path", {
          attrs: {
            d: "M100.885 277.015l13.306.092s1.291-54.228 1.843-64.056c.552-9.828 3.756-43.13.997-62.788l-12.48-.64-22.725.776s-.433 3.944-1.19 9.921c-.062.493-.677.838-.744 1.358-.075.582.42 1.347.318 1.956-2.35 14.003-6.343 32.926-8.697 46.425-.116.663-1.227 1.004-1.45 2.677-.04.3.21 1.516.112 1.785-6.836 18.643-10.89 47.584-14.2 61.551l14.528-.014s2.185-8.524 4.008-16.878c2.796-12.817 22.987-84.553 22.987-84.553l3-.517 1.037 46.1s-.223 1.228.334 2.008c.558.782-.556 1.117-.39 2.233l.39 1.784s-.446 7.14-.892 11.826c-.446 4.685-.092 38.954-.092 38.954",
            fill: "#7BB2F9"
          }
        }), h("path", {
          attrs: {
            d: "M77.438 220.434c1.146.094 4.016-2.008 6.916-4.91M107.55 223.931s2.758-1.103 6.069-3.862",
            stroke: "#648BD8",
            strokeWidth: "1.051",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M108.459 220.905s2.759-1.104 6.07-3.863",
            stroke: "#648BD8",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M76.099 223.557s2.608-.587 6.47-3.346M87.33 150.82c-.27 3.088.297 8.478-4.315 9.073M104.829 149.075s.11 13.936-1.286 14.983c-2.207 1.655-2.975 1.934-2.975 1.934M101.014 149.63s.035 12.81-1.19 24.245M94.93 174.965s7.174-1.655 9.38-1.655M75.671 204.754c-.316 1.55-.64 3.067-.973 4.535 0 0-1.45 1.822-1.003 3.756.446 1.934-.943 2.034-4.96 15.273-1.686 5.559-4.464 18.49-6.313 27.447-.078.38-4.018 18.06-4.093 18.423M77.043 196.743a313.269 313.269 0 0 1-.877 4.729M83.908 151.414l-1.19 10.413s-1.091.148-.496 2.23c.111 1.34-2.66 15.692-5.153 30.267M57.58 272.94h13.238",
            stroke: "#648BD8",
            strokeWidth: "1.051",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        }), h("path", {
          attrs: {
            d: "M117.377 147.423s-16.955-3.087-35.7.199c.157 2.501-.002 4.128-.002 4.128s14.607-2.802 35.476-.31c.251-2.342.226-4.017.226-4.017",
            fill: "#192064"
          }
        }), h("path", {
          attrs: {
            d: "M107.511 150.353l.004-4.885a.807.807 0 0 0-.774-.81c-2.428-.092-5.04-.108-7.795-.014a.814.814 0 0 0-.784.81l-.003 4.88c0 .456.371.82.827.808a140.76 140.76 0 0 1 7.688.017.81.81 0 0 0 .837-.806",
            fill: "#FFF"
          }
        }), h("path", {
          attrs: {
            d: "M106.402 149.426l.002-3.06a.64.64 0 0 0-.616-.643 94.135 94.135 0 0 0-5.834-.009.647.647 0 0 0-.626.643l-.001 3.056c0 .36.291.648.651.64 1.78-.04 3.708-.041 5.762.012.36.009.662-.279.662-.64",
            fill: "#192064"
          }
        }), h("path", {
          attrs: {
            d: "M101.485 273.933h12.272M102.652 269.075c.006 3.368.04 5.759.11 6.47M102.667 263.125c-.009 1.53-.015 2.98-.016 4.313M102.204 174.024l.893 44.402s.669 1.561-.224 2.677c-.892 1.116 2.455.67.893 2.231-1.562 1.562.893 1.116 0 3.347-.592 1.48-.988 20.987-1.09 34.956",
            stroke: "#648BD8",
            strokeWidth: "1.051",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        })]
      )]
    );
  }
};

export default Unauthorized;