export function hasClass(node, className) {
  if (node.classList) {
    return node.classList.contains(className);
  }
  var originClass = node.className;
  return (' ' + originClass + ' ').indexOf(' ' + className + ' ') > -1;
}

export function addClass(node, className) {
  if (node.classList) {
    node.classList.add(className);
  } else {
    if (!hasClass(node, className)) {
      node.className = node.className + ' ' + className;
    }
  }
}

export function removeClass(node, className) {
  if (node.classList) {
    node.classList.remove(className);
  } else {
    if (hasClass(node, className)) {
      var originClass = node.className;
      node.className = (' ' + originClass + ' ').replace(' ' + className + ' ', ' ');
    }
  }
}